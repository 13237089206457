import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function NavBar() {
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  return (
    <div>
      <>
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton className=" text-dark">
            <Modal.Title>
              {" "}
              <h3 class="container">About Us</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-dark">
            <section>
              <div class="container shadow-lg p-3 mb-5 bg-body text-dark rounded">
                <p>
                  At Aus Trust Investment Bank, we believe in the power of
                  financial insights and the impact they can have on our
                  clients' success. As a leading global investment banking
                  institution, we offer an extensive portfolio of services,
                  including corporate finance, sales and trading, equity
                  research, asset management, and much more. Our dedicated teams
                  work relentlessly to provide personalized solutions that cater
                  to your unique needs and financial goals. We are committed to
                  delivering superior client service and fostering enduring
                  relationships built on trust and integrity. Partner with us
                  and experience the Aus Trust difference in transforming your
                  financial aspirations into achievements.
                </p>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose2}
              className="bg-dark"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      {/* down navbar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light "
        bg=""
        className="navBar p-3 d-flex justify-content-between align-items-center "
      >
        <Container fluid>
          <Link to="/" className="text-decoration-none navitem">
            <Navbar.Brand
              className="navBrand fw-bold"
              style={{ color: "white" }}
            >
              <span class="AV">ATIB</span>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-center"
          >
            <Nav>
              <Nav.Link className="navLinkHover">
                <Link
                  to="/"
                  className="text-decoration-none navitem"
                  id="#home"
                >
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link className="navLinkHover">
                <Link to="/services" className="text-decoration-none navitem">
                  Our Services
                </Link>
              </Nav.Link>

              <Nav.Link className="navLinkHover">
                <Link to="/contact-us" className="text-decoration-none navitem">
                  Contact Us
                </Link>
              </Nav.Link>
              <Nav.Link className="navLinkHover" onClick={handleShow2}>
                <Link to="#" className="text-decoration-none navitem">
                  About Us
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse
            id="responsive-navbar-nav "
            className="justify-content-end"
          >
            <div className="d-flex">
              <Nav.Link className="navLinkHover"></Nav.Link>
              <a href="/sign-in">
                <button
                  type="button"
                  className="btn btn-outline-primary text-light"
                >
                  Sign In
                </button>
              </a>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;
