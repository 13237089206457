import React, {Component} from 'react';
import Admin from '../Dashboard/Admin';

class Home extends Component {

   

    render(){
        return(
            <section>
         <div>
            <Admin />
                
         </div> 
            </section>
           
        )
    }
}

export default Home;