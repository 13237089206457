import React from "react";
import NavBar from "./NavBar";
// import Carousel from "react-bootstrap/Carousel";
// import { CarouselItem } from "react-bootstrap";
import Footer from "./Footer";
// import { AiFillStar } from "react-icons/ai";

function Services() {
  return (
    <div className="text-dark">
      <NavBar />
      <div class="container-fluid serviceBg"></div>

      <div class="container">
        <h3 class="text-center mt-5">Our Services</h3>
      </div>
      <hr />

      {/* service card1 */}
      <section class="container">
        <div class="service-parent-container">
          <div class="service-child-container1">
            <img
              src="img/corporate-finance.jpg"
              class="serviceFlexImg container shadow-sm p-0 mb-5 dark rounded"
              alt=""
            />
          </div>
          <div class="service-child-container2">
            <h5 class="text-center container p-2 serviceText">
              Corporate Finance
            </h5>
            <p>
              Corporate finance optimizes a company's financial management to
              maximize shareholder value. It covers investment decisions,
              capital structure, mergers, acquisitions, and financial risk
              management, steering businesses towards growth and profitability.
            </p>
          </div>
          <div class="service-child-container3">
            <img
              src="img/sales.jpg"
              class="serviceFlexImg  container shadow-sm p-0 mb-5 dark rounded"
              alt=""
            />
          </div>
          <div class="service-child-container4">
            <h5 class="text-center container p-2 serviceText">
              Sales and Trading
            </h5>
            <p>
              Sales and Trading is the engine of capital markets. It involves
              buying and selling securities like stocks, bonds, derivatives,
              commodities for clients. Traders make markets, while sales
              professionals manage client relationships.
            </p>
          </div>
        </div>
      </section>

      {/* service card2 */}
      <section class="container">
        <div class="service-parent-container">
          <div class="service-child-container1">
            <img
              src="img/equity-research.png"
              class="serviceFlexImg  container shadow-sm p-0 mb-5 dark rounded"
              alt=""
            />
          </div>
          <div class="service-child-container2">
            <h5 class="text-center container p-2 serviceText">
              Equity Research
            </h5>
            <p>
              Equity Research provides in-depth analysis of companies,
              industries, and markets to assist investors in making informed
              investment decisions. Analysts evaluate financial data, track
              trends, and generate forecasts, contributing to strategic
              financial planning.
            </p>
          </div>
          <div class="service-child-container3">
            <img
              src="img/Asset-Management.jpeg"
              class="serviceFlexImg  container shadow-sm p-0 mb-5 dark rounded"
              alt=""
            />
          </div>
          <div class="service-child-container4">
            <h5 class="text-center container p-2 serviceText">
              Asset Management
            </h5>
            <p>
              Asset Management focuses on investing client funds to meet
              specified goals. This can involve a range of assets, including
              stocks, bonds, real estate, and more. The key aim is maximizing
              returns while managing risk.
            </p>
          </div>
        </div>
      </section>

      {/* service card3 */}
      <section class="container">
        <div class="service-parent-container">
          <div class="service-child-container1">
            <img
              src="img/wealth-management.jpg"
              class="serviceFlexImg  container shadow-sm p-0 mb-5 dark rounded"
              alt=""
            />
          </div>
          <div class="service-child-container2">
            <h5 class="text-center container p-2 serviceText">
              Wealth Management
            </h5>
            <p>
              Wealth Management at Aus Trust Investment Bank provides tailored
              strategies to manage and grow wealth. Our advisors offer financial
              planning, portfolio management, and other financial services to
              help clients achieve their financial goals.
            </p>
          </div>
          <div class="service-child-container3">
            <img
              src="img/risk-management.jpeg"
              class="serviceFlexImg  container shadow-sm p-0 mb-5 dark rounded"
              alt=""
            />
          </div>
          <div class="service-child-container4">
            <h5 class="text-center container p-2 serviceText">
              Risk Management
            </h5>
            <p>
              Risk Management at Aus Trust Investment Bank involves identifying,
              evaluating, and mitigating risks that could hinder the bank's
              operations or profitability. We use advanced tools and strategies
              to forecast and evaluate potential risks and devise effective
              countermeasures.
            </p>
          </div>
        </div>
      </section>

      <hr />
      <br />
      <br />

      {/* client testimonials */}
      {/* <Carousel>
        <CarouselItem>
          <section class="container">
            <h2 style={{ color: "blue" }} class="text-center">
              Testimonials
            </h2>
            <br />
            <br />
            <div>
              <div class="row">
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/brooke.jpg"
                          alt="Brooke"
                          class="ImgFlipBack"
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          Working with these gentlemen is a joy. I've already
                          used them twice and each time I found a fantastic
                          deal. They are incredibly welcoming and effective!
                          They are sincere, above anything else. strongly
                          advised.
                        </q>
                        <br />
                        <br />

                        <h6>Brooke Trease</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/Rose.jpg"
                          alt="Rose"
                          class="ImgFlipBack"
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          Ghana Gold Vault is a product about which I can only
                          speak favorably. They've been helpful to me twice, and
                          I'd recommend using them as well. Service that is
                          cordial, prompt, and efficient. The turnaround time is
                          so short and stress-free—you just need to fill out a
                          form and put the bag in a post box. I appreciate it.
                        </q>
                        <br />
                        <br />
                        <h6>Rose Bautista</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/Norton.jpg"
                          alt="Norton"
                          class="ImgFlipBack"
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          GVV delivered first-rate service. When my package
                          arrived at their location, I was offered a fantastic
                          return on my gold because the entire process went
                          smoothly.I appreciate it.
                        </q>
                        <br />
                        <br />
                        <h6>Norton-McKellar</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>

        <CarouselItem>
          <section class="container">
            <h2 style={{ color: "blue" }} class="text-center">
              Testimonials
            </h2>
            <br />
            <br />
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/jennie.jpg"
                          alt="James"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          I was shocked at the value of what I had thought to be
                          rubbish. The staff was really kind and accommodating.
                          I'll advise others to use Ghana Gold Vault.
                        </q>
                        <br />
                        <br />

                        <h6>James Wood</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/lynsey.jpg"
                          alt="Lynsey"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        Fantastic to work with The communication was very quick, I would definitely recommend it!
                        </q>
                        <br />
                        <br />
                        <h6>Nicholson Clerk</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/marion.jpg"
                          alt="Marion"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          Excellent rapid service, user-friendly design, and
                          reasonable pricing. Assets Vaults, thank you for
                          making everything so simple.
                        </q>
                        <br />
                        <br />
                        <h6>Marion Carr</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>

        <CarouselItem>
          <section class="container">
            <h2 style={{ color: "blue" }} class="text-center">
              Testimonials
            </h2>
            <br />
            <br />
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/natalie.jpg"
                          alt="Natalie Dray"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        From start to finish, excellent service. Very pleased with the service.
                        </q>
                        <br />
                        <br />

                        <h6>Natalie Dray</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/valarie.jpg"
                          alt="Valarie"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        Outstanding customer service; prompt, courteous responses.
                        </q>
                        <br />
                        <br />
                        <h6>Valerie Towers</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/Anne kory.jpg"
                          alt="Anne Kory"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        This process was very quick and straightforward. The products and loose stones were returned in sensible packing, and the pricing was really reasonable.
                        </q>
                        <br />
                        <br />
                        <h6>Felix Rodri</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>
      </Carousel> */}
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Services;
