import React from 'react'


function AdminFooter() {



  return (
    <div>
      <br />
 


        <footer className='container-fluid adminFooter d-flex justify-content-around align-items-center '>
        <div>
        <span class="AV fw-bold">ATIB</span>
         <span>   Aus Trust Investment Bank</span>
        </div>
        
        <div>&copy; 2023 copyright || All right reserved.</div>
       
        </footer>
    </div>
  )
}

export default AdminFooter