import React, { useEffect, useState } from 'react';
import { FaCreditCard } from 'react-icons/fa';
import './DepositPage.css';
import { Navbar, Container, Dropdown,Nav} from "react-bootstrap";
import { FaUserAlt, FaSignOutAlt } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../config/Fire";
import AdminFooter from './AdminFooter';
import ServicesList from "./ServicesList";




const DepositPage = () => {
  const [user, loading] = useAuthState(auth);
  const [depositAmount, setDepositAmount] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/sign-in");
  }, [user, loading, navigate]);

  const handleDeposit = (e) => {
    e.preventDefault();

    // Validate the deposit amount
    if (depositAmount.trim() === '') {
      alert('Please enter a valid deposit amount.');
      return;
    }

    // Perform the deposit logic
    // You can add your custom logic here, such as making an API call or updating the user's account balance

    // Reset the deposit amount
    setDepositAmount('');

    // Show a success message to the user
    alert(`Deposit of $${depositAmount} was not successful. Please contact us through this email austrustinvestment@gmail.com.`);
  };

  return (
    <section>
      {/* top navigation bar */}
      <nav className="adminNavBar container-fluid d-flex justify-content-between align-items-center">
        <div>
          <a class="navbar-brand container" href>
            <span class="AV fw-bold">ATIB</span>{" "}
          </a>
        </div>
        <div>
          <Dropdown className="me-3 ">
            <Dropdown.Toggle className="user-icon" id="dropdown-basic">
              <FaUserAlt />
            </Dropdown.Toggle>{" "}
            <Dropdown.Menu>
              <Dropdown.Item className="dropdown-hover">
                {user?.email}
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-hover" onClick={logout}>
                logout <FaSignOutAlt />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
      {/* down navigation bar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="admin-down-navbar p-3"
        variant="dark"
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/admin">Home</Nav.Link>

              <Nav.Link href="/profile">Profile</Nav.Link>
              <Nav.Link href="/deposit">Deposit</Nav.Link>
              <Nav.Link href="/withdraw">Withdraw</Nav.Link>
              <Nav.Link href="/transfer">Transfer</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>




            </Nav>
          </Navbar.Collapse>
          {/* <Navbar.Collapse
            id="responsive-navbar-nav "
            className="justify-content-end"
          >
            <div>
              <div className=" d-flex">
                <input
                  type="text"
                  placeholder="search your name here"
                  value={wordEntered}
                  onChange={handleFilter}
                  className="form-control search-width"
                />
                <div className="ms-2 searchIcon">
                  {filteredData.length === 0 ? (
                    <FaSearch />
                  ) : (
                    <RiCloseFill id="clearBtn" onClick={clearInput} />
                  )}
                </div>
              </div>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <br />
      <br />

      <div className="deposit-page">
        <h2 className="deposit-page__title">Deposit Funds</h2>
        <div className="deposit-page__card">
          <FaCreditCard className="deposit-page__card-icon" />
          <p className="deposit-page__card-text">Visa Card</p>
        </div>
        <form className="deposit-page__form" onSubmit={handleDeposit}>
          <div className="form-group">
            <label htmlFor="cardNumber">Card Number</label>
            <input type="text" id="cardNumber" className="form-control" required/>
          </div>
          <div className="form-group">
            <label htmlFor="name">Cardholder Name</label>
            <input type="text" id="name" className="form-control" required/>
          </div>
          <div className="form-group">
            <label htmlFor="expiry">Expiry Date</label>
            <input type="text" id="expiry" className="form-control" required/>
          </div>
          <div className="form-group">
            <label htmlFor="cvv">CVV</label>
            <input type="text" id="cvv" className="form-control" placeholder="123" required/>
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              className="form-control"
              placeholder="$0"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
      <hr />
<ServicesList />
<hr />
<p className="container mt-5">
        At Aus Trust Investment Bank, we're focused on providing our clients
        with tailored investment solutions to meet their specific financial
        goals. Our experienced team offers services ranging from cooperate
        finance, equity research, to sales and trading. We also specialize in
        asset and risk management as well as wealth management, all geared
        towards maximizing our client's investment potential. We believe that
        with our expertise, we can guide you on the path to financial success.
        It would be a great pleasure to have a detailed discussion with you
        regarding your investment objectives, and how we can assist you in
        achieving them.
      </p>
      <hr />
      <AdminFooter />
    </section>
  );
};

export default DepositPage;
