import React,{useState,useEffect} from 'react';
// import { FaCreditCard } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';
import './TransferMoney.css';
import { Navbar, Container, Dropdown, Nav } from "react-bootstrap";
import { FaUserAlt, FaSignOutAlt } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../config/Fire";
import AdminFooter from './AdminFooter';
import ServicesList from './ServicesList';
import{BiArrowToBottom,BiRightArrowAlt} from 'react-icons/bi'
import { Link } from "react-router-dom";




const TransferPage = () => {



    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();

    const [amount, setAmount] = useState('');
    const [recipient, setRecipient] = useState('');
    const [swift, setSwift]= useState('')
    const [bsb, setBSB]= useState('')
    const [accnumber, setAccountNumber]= useState('')
    const [transferSuccess, setTransferSuccess] = useState(false);
  
    const handleAmountChange = (e) => {
      setAmount(e.target.value);
    };
    const handleSwiftChange = (e) => {
      setSwift(e.target.value);
    };
    const handleBsbChange = (e) => {
      setBSB(e.target.value);
    };

    const handleAccountNumberChange = (e) => {
      setAccountNumber(e.target.value);
    };

  
    const handleRecipientChange = (e) => {
      setRecipient(e.target.value);
    };
  
    const handleTransfer = (e) => {
      e.preventDefault();
      // Perform transfer logic here
      console.log('Transfer:', amount, 'to', recipient);
      // Reset form fields
      setAmount('');
      setRecipient('');
      // Display success message
      setTransferSuccess(true);
    };
  
    const closeAlert = () => {
      setTransferSuccess(false);
    };


    useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/sign-in");
    }, [user, loading, navigate]);
  





  return (
    



<section>

     {/* top navigation bar */}
     <nav className="adminNavBar container-fluid d-flex justify-content-between align-items-center">
        <div>
          <a class="navbar-brand container" href>
            <span class="AV fw-bold">ATIB</span>{" "}
          </a>
        </div>
        <div>
          <Dropdown className="me-3 ">
            <Dropdown.Toggle className="user-icon" id="dropdown-basic">
              <FaUserAlt />
            </Dropdown.Toggle>{" "}
            <Dropdown.Menu>
              <Dropdown.Item className="dropdown-hover">
                {user?.email}
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-hover" onClick={logout}>
                logout <FaSignOutAlt />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
      {/* down navigation bar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="admin-down-navbar p-3"
        variant="dark"
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/admin">Home</Nav.Link>

              <Nav.Link href="/profile">Profile</Nav.Link>
              <Nav.Link href="/deposit">Deposit</Nav.Link>
              <Nav.Link href="/withdraw">Withdraw</Nav.Link>
              <Nav.Link href="/transfer">Transfer</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>




            </Nav>
          </Navbar.Collapse>
          {/* <Navbar.Collapse
            id="responsive-navbar-nav "
            className="justify-content-end"
          >
            <div>
              <div className=" d-flex">
                <input
                  type="text"
                  placeholder="search your name here"
                  value={wordEntered}
                  onChange={handleFilter}
                  className="form-control search-width"
                />
                <div className="ms-2 searchIcon">
                  {filteredData.length === 0 ? (
                    <FaSearch />
                  ) : (
                    <RiCloseFill id="clearBtn" onClick={clearInput} />
                  )}
                </div>
              </div>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <br />
      <br />


      <div className="transfer-money">
      <h2>Transfer Money</h2>
      <form onSubmit={handleTransfer}>
        <div className="form-group">
          <label htmlFor="recipient">Account Holder Name:</label>
          <input
            type="text"
            id="recipient"
            value={recipient}
            onChange={handleRecipientChange}
            className="form-control"
            placeholder="Enter  account holder name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="recipient">Account Number:</label>
          <input
            type="text"
            id="recipient"
            value={accnumber}
            onChange={handleAccountNumberChange}
            className="form-control"
            placeholder="Enter  account number"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="recipient">Swift Code:</label>
          <input
            type="text"
            id="recipient"
            value={swift}
            onChange={handleSwiftChange}
            className="form-control"
            placeholder="Enter  swift code here..."
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="recipient">BSB Number:</label>
          <input
            type="text"
            id="recipient"
            value={bsb}
            onChange={handleBsbChange}
            className="form-control"
            placeholder="Enter  bsb number"
            required
          />
        </div>


        <div className="form-group">
          <label htmlFor="recipient">Country:</label>
          <input
            type="text"
            id="recipient"
            value={bsb}
            onChange={handleBsbChange}
            className="form-control"
            placeholder="Enter country "
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="amount">Amount:</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={handleAmountChange}
              className="form-control"
              placeholder="Enter amount"
              required
            />
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Transfer <BsArrowRight className="ml-2" />
        </button>
      </form>
      {transferSuccess && (
        <div className="alert alert-success" role="alert">
          Transfer unsuccessful, please contact us through this email austrustinvestment@gmail.com
          <button type="button" className="close " onClick={closeAlert}>
            <span>&times;</span>
          </button>
        </div>
      )}
    </div>
    <div className='container text-center mt-5'><BiArrowToBottom  className = 'fs-1'/> </div>
    <div className='container text-center mt-5 fw-bold text-success'><Link to='#' onClick={() => {alert("Please contact us directly through this email austrustinvestment@gmail.com")}}>Wire Transfers</Link> <BiRightArrowAlt className='fs-3' /> </div>
    <hr />
<ServicesList />
<hr />
<p className="container mt-5">
        At Aus Trust Investment Bank, we're focused on providing our clients
        with tailored investment solutions to meet their specific financial
        goals. Our experienced team offers services ranging from cooperate
        finance, equity research, to sales and trading. We also specialize in
        asset and risk management as well as wealth management, all geared
        towards maximizing our client's investment potential. We believe that
        with our expertise, we can guide you on the path to financial success.
        It would be a great pleasure to have a detailed discussion with you
        regarding your investment objectives, and how we can assist you in
        achieving them.
      </p>
      <hr />

    <AdminFooter />

</section>

  );
};

export default TransferPage;
