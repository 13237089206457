import React from 'react';
// import { ListGroup } from 'react-bootstrap';

const ServicesList = () => {
    const services = [
        'Cooperate Finance', 
        'Equity Research', 
        'Sales and Trading',
        'Asset Management',
        'Risk Management', 
        'Wealth Management'
    ];

    return (
        <div className="container" style={{ maxWidth: '600px', margin: '0 auto', marginTop: '50px'}}>
            <h2 className="mt-4" style={{ textAlign: 'center', marginBottom: '30px', color: '#3a3a3a'}}>
                Services
            </h2>
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                {services.map((service, index) => (
                    <div key={index} style={{ backgroundColor: '#f8f9fa', borderColor: '#dee2e6', borderWidth: '1px', borderStyle: 'solid', color: '#3a3a3a', fontSize: '18px', padding: '20px', marginBottom: '10px', flex: '0 0 48%', borderRadius: '10px', textAlign: 'center'}}>
                        {service}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ServicesList;
