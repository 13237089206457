import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { FaChartBar, FaChartPie} from 'react-icons/fa';
import { GiHistogram } from 'react-icons/gi';
import './BankDashboard.css'; // Updated CSS file for styling

// Register all controllers, elements, scales & plugins of Chart.js
Chart.register(...registerables);

const BankDashboard = () => {
  // Bar and Histogram chart options
  const barChartOptions = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM'
          },
          parser: 'MMM',
        },
        labels: ['January', 'February', 'March', 'April', 'May', 'June'] // For Bar chart
      },
      y: {
        type: 'linear'
      }
    }
  };

  // Bar chart data
  const barChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'All Year-Round Transactions (2022-2023)',
        data: [2000, 305400, 1500, 49800, 285500, 63500],
        backgroundColor: 'rgba(75, 192, 192, 0.9)',
      },
    ],
  };

  // Pie chart data
  const pieChartData = {
    labels: ['Savings', 'Tax', 'FX','Deposits','Checkings'],
    datasets: [
      {
        data: [30000000, 25000, 0,12566,75232],
        backgroundColor: ['#ff6384', '#36a2eb', '#800000','#000080','#40E0D0'],
      },
    ],
  };

  // Histogram data
  const histogramData = {
    labels: ['0-10K', '10K-20K', '20K-30K', '30K-40K', '40K-50K'],
    datasets: [
      {
        label: 'Amount',
        data: [1000, 3500, 8000, 120000, 85000],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };

  return (
    <div className="bank-dashboard-container">
      <div className="row">
        <div className="col-md-4">
          <div className="dashboard-card">
            <div className="card-body">
              <h5 className="dashboard-card-title">
                <FaChartBar className="chart-icon" /> All Year-Round Transactions
              </h5>
              <Bar data={barChartData} options={barChartOptions} />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-card">
            <div className="card-body">
              <h5 className="dashboard-card-title">
                <FaChartPie className="chart-icon" /> Current Transaction
              </h5>
              <Pie data={pieChartData} />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-card">
            <div className="card-body">
              <h5 className="dashboard-card-title">
                <GiHistogram  className="chart-icon" /> Monthly Transactions
              </h5>
              <Bar data={histogramData} options={barChartOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDashboard;
