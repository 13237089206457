import React, { useState } from "react";
// import { CarouselItem } from "react-bootstrap";
// import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Footer from "./Footer";
// import { AiFillStar } from "react-icons/ai";

function Background() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <div>
      {/* Modal */}
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="">
            <Modal.Title>
              {" "}
              <h1 class="container">Mail-in Service</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-dark">
            <section>
              <div class="container shadow-lg p-3 mb-5 bg-body rounded">
                <form
                  action="https://ghana-gold-vault.herokuapp.com/mail-service"
                  method="post"
                >
                  <div class="form-group m-3">
                    <label for="name">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="mailName"
                      placeholder="Enter your name"
                      required
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      name="mailEmail"
                      placeholder="example@gmail.com"
                      required
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="address">Address</label>
                    <input
                      type="address"
                      class="form-control"
                      name="address"
                      placeholder="Enter your address"
                      required
                    />
                  </div>

                  <button type="submit" class="btn btn-warning mt-5">
                    Submit
                  </button>
                </form>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="bg-dark"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <section className="bgsection container shadow-lg p-3 mb-5 bg-body rounded mt-5">
        <div className="backgroundText">
          <h1 className="fw-bold">Welcome to Aus Trust Investment Bank</h1>
          <h4 className="fw-normal">
            At Aus Trust Investment Bank, our primary objective is to serve the
            unique financial needs of our clients. We offer a wide range of
            comprehensive investment banking services, including corporate
            finance advisory, mergers & acquisitions, asset management, equity
            research, risk management, and more.
          </h4>
          <a href="sign-in">
            <button className="btn btn-outline-primary  m-5">Sign In</button>
          </a>
        </div>
        <div className="backgroundImg ">
          <img
            src="img/InvestmentBank.jpg"
            alt=""
            width="100%"
            className="rounded-3"
          />
        </div>
      </section>

      <hr />
      <br />
      <br />

      <section class="container">
        <div class="parentCard">
          <div class="card1">
            <div class="card shadow-lg  mb-5 bg-body rounded ">
              <img
                src="img/corporate-finance.jpg"
                class="card-img-top cardFlexImg"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Corporate Finance</h5>
                <p class="card-text">
                  Corporate finance optimizes a company's financial management
                  to maximize shareholder value. It covers investment decisions,
                  capital structure, mergers, acquisitions, and financial risk
                  management, steering businesses towards growth and
                  profitability.
                </p>
              </div>
            </div>
          </div>

          <div class="card4">
            <div class="card shadow-lg  mb-5 bg-body rounded">
              <img
                src="img/sales.jpg"
                class="card-img-top cardFlexImg"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Sales and Trading</h5>
                <p class="card-text">
                  Sales and Trading is the engine of capital markets. It
                  involves buying and selling securities like stocks, bonds,
                  derivatives, commodities for clients. Traders make markets,
                  while sales professionals manage client relationships.
                </p>
              </div>
            </div>
          </div>

          <div class="card3">
            <div class="card shadow-lg  mb-5 bg-body rounded">
              <img
                src="img/equity-research.png"
                class="card-img-top cardFlexImg"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Equity Research</h5>
                <p class="card-text">
                  Equity Research provides in-depth analysis of companies,
                  industries, and markets to assist investors in making informed
                  investment decisions. Analysts evaluate financial data, track
                  trends, and generate forecasts, contributing to strategic
                  financial planning.
                </p>
              </div>
            </div>
          </div>

          <div class="card2">
            <div class="card shadow-lg  mb-5 bg-body rounded ">
              <img
                src="img/Asset-Management.jpeg"
                class="card-img-top cardFlexImg"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">Asset Management</h5>
                <p class="card-text">
                  Asset Management focuses on investing client funds to meet
                  specified goals. This can involve a range of assets, including
                  stocks, bonds, real estate, and more. The key aim is
                  maximizing returns while managing risk.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <br />
      <br />
      <br />
      <hr />
      <br />
      <br />

      {/* About us */}
      <section class="container parentContainer">
        <div class="child-container3">
          <h2 style={{ color: "blue" }} id="about">
            About Us
          </h2>
          <p class="fs-6">
            At Aus Trust Investment Bank, we believe in the power of financial
            insights and the impact they can have on our clients' success. As a
            leading global investment banking institution, we offer an extensive
            portfolio of services, including corporate finance, sales and
            trading, equity research, asset management, and much more. Our
            dedicated teams work relentlessly to provide personalized solutions
            that cater to your unique needs and financial goals. We are
            committed to delivering superior client service and fostering
            enduring relationships built on trust and integrity. Partner with us
            and experience the Aus Trust difference in transforming your
            financial aspirations into achievements.
          </p>
        </div>
        <div class="child-container4">
          <img
            src="img/Investment-Banking.jpg"
            alt=""
            width="100%"
            className="rounded-3"
          />
        </div>
      </section>

      <br />
      <br />
      <br />
      <br />
      <section class="container parentContainer">
        <div class="child-container1">
          <img
            src="img/Barrick.jfif"
            alt="Peter Walker"
            width="50%"
            class="managerImg shadow-lg mb-5 bg-body rounded"
          />
        </div>
        <div class="child-container2">
          <h6 style={{ color: "blue" }}>Managing Director</h6>
          <h2 style={{ color: "blue" }}>James Wood</h2>
          <p class="fs-6">
            As the Managing Director of Aus Trust Investment Bank, I am proud to
            be at the helm of an institution that places customer success at the
            forefront of all its operations. Our aim is to empower clients with
            comprehensive financial solutions that provide not just returns, but
            peace of mind and confidence in their financial future.
          </p>
        </div>
      </section>
      <br />
      <hr />
      {/* client testimonials */}
      {/* <Carousel>
        <CarouselItem>
          <section class="container">
            <h2 style={{ color: "blue" }} class="text-center">
              Testimonials
            </h2>
            <br />
            <br />
            <div>
              <div class="row">
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/brooke.jpg"
                          alt="Brooke"
                          class="ImgFlipBack rounded-3 "
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          Working with these gentlemen is a joy. I've already
                          used them twice and each time I found a fantastic
                          deal. They are incredibly welcoming and effective!
                          They are sincere, above anything else. strongly
                          advised.
                        </q>
                        <br />
                        <br />

                        <h6>Brooke Trease</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/Rose.jpg"
                          alt="Rose"
                          class="ImgFlipBack rounded-3"
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          Ghana Gold Vault is a product about which I can only
                          speak favorably. They've been helpful to me twice, and
                          I'd recommend using them as well. Service that is
                          cordial, prompt, and efficient. The turnaround time is
                          so short and stress-free—you just need to fill out a
                          form and put the bag in a post box. I appreciate it.
                        </q>
                        <br />
                        <br />
                        <h6>Rose Bautista</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/Norton.jpg"
                          alt="Norton"
                          class="ImgFlipBack rounded-3"
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                           GVV delivered first-rate service. When my package
                          arrived at their location, I was offered a fantastic
                          return on my gold because the entire process went
                          smoothly.I appreciate it.
                        </q>
                        <br />
                        <br />
                        <h6>Norton-McKellar</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>

        <CarouselItem>
          <section class="container">
            <h2 style={{ color: "blue" }} class="text-center">
              Testimonials
            </h2>
            <br />
            <br />
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/jennie.jpg"
                          alt="James"
                          class="ImgFlipBack rounded-3"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          I was shocked at the value of what I had thought to be
                          rubbish. The staff was really kind and accommodating.
                          I'll advise others to use Ghana Gold Vault.
                        </q>
                        <br />
                        <br />

                        <h6>James Wood</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/lynsey.jpg"
                          alt="Lynsey"
                          class="ImgFlipBack rounded-3"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        Fantastic to work with The communication was very quick, I would definitely recommend it!
                        </q>
                        <br />
                        <br />
                        <h6> Nicholson Clerk</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/marion.jpg"
                          alt="Marion"
                          class="ImgFlipBack rounded-3"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                          Excellent rapid service, user-friendly design, and
                          reasonable pricing. Assets Vaults, thank you for
                          making everything so simple.
                        </q>
                        <br />
                        <br />
                        <h6>Marion Carr</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>

        <CarouselItem>
          <section class="container">
            <h2 style={{ color: "blue" }} class="text-center">
              Testimonials
            </h2>
            <br />
            <br />
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/natalie.jpg"
                          alt="Natalie Dray"
                          class="ImgFlipBack rounded-3"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        From start to finish, excellent service. Very pleased with the service.
                        </q>
                        <br />
                        <br />

                        <h6>Natalie Dray</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/valarie.jpg"
                          alt="Valarie"
                          class="ImgFlipBack rounded-3"
                          width={300}
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        Outstanding customer service; prompt, courteous responses.
                        </q>
                        <br />
                        <br />
                        <h6>Valerie Towers</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="flip-card shadow-lg p-3 mb-5 bg-body rounded">
                    <div class="flip-card-inner">
                      <div class="flip-card-back">
                        <img
                          src="img/Anne kory.jpg"
                          alt="Anne Kory"
                          width={300}
                          class="ImgFlipBack rounded-3"
                          height={300}
                        />
                      </div>
                      <div class="flip-card-front p-1">
                        <q>
                        This process was very quick and straightforward. The products and loose stones were returned in sensible packing, and the pricing was really reasonable.
                        </q>
                        <br />
                        <br />
                        <h6>Felix Rodri</h6>
                        <div class="d-flex justify-content-center">
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                          <AiFillStar style={{ color: "blue" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CarouselItem>
      </Carousel> */}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Footer />
    </div>
  );
}

export default Background;
