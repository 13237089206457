import React, { useState, useEffect } from "react";
import { auth, logout } from "../config/Fire";
// import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { FaUserAlt, FaSignOutAlt } from "react-icons/fa";
import { Navbar, Container, Dropdown, Nav } from "react-bootstrap";
// import AdminBackground from './AdminBackground';
import AdminFooter from "./AdminFooter";
// import { FaSearch } from "react-icons/fa";
// import { RiCloseFill } from "react-icons/ri";
// import BookData from "../Data";
import data from "./data.json";
// import Transaction from "./Transaction";
// import { Card, ListGroup } from "react-bootstrap";
import Profile from "./Profile";
import './card.css';
import BankDashboard from "./BankDashboard";
import ServicesList from "./ServicesList";
// import {RiRadioButtonLine} from 'react-icons/ri'

function Admin() {
  // const [wordEntered, setWordEntered] = useState("");
  // const [filteredData, setFilteredData] = useState([]);

  const [user1, setUser] = useState(null);
  // const [fixedDeposit, setFixedDeposit] = useState(0);
  // const [creditCardBalance, setCreditCardBalance] = useState(0);
  // const [savings, setSavings] = useState(0);
  // const [availableBalance, setAvailableBalance] = useState(0);

  useEffect(() => {
    setUser(data.userDetail);
 
    // setFixedDeposit(data.fixedDeposit);
    // setCreditCardBalance(data.creditCardBalance);
    // setSavings(data.savings);
    // setAvailableBalance(data.availableBalance);
  }, []);

  // const handleFilter = (event) => {
  //   const searchWord = event.target.value;
  //   setWordEntered(searchWord);
  //   const newFilter = BookData.filter((value) => {
  //     return value.mainContent.toLowerCase().includes(searchWord.toLowerCase());
  //   });

  //   if (searchWord === "") {
  //     setFilteredData([]);
  //   } else {
  //     setFilteredData(newFilter);
  //   }
  // };

  // const clearInput = () => {
  //   setFilteredData([]);
  //   setWordEntered("");
  // };

  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/sign-in");
  }, [user, loading, navigate]);

  return (
    <div>
      {/* top navigation bar */}
      <nav className="adminNavBar container-fluid d-flex justify-content-between align-items-center">
        <div>
          <a class="navbar-brand container" href>
            <span class="AV fw-bold">ATIB</span>{" "}
          </a>
        </div>
        <div>
          <Dropdown className="me-3 ">
            <Dropdown.Toggle className="user-icon" id="dropdown-basic">
              <FaUserAlt />
            </Dropdown.Toggle>{" "}
            <Dropdown.Menu>
              <Dropdown.Item className="dropdown-hover">
                {user?.email}
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-hover" onClick={logout}>
                logout <FaSignOutAlt />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
      {/* down navigation bar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="admin-down-navbar p-3"
        variant="dark"
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/admin">Home</Nav.Link>

              <Nav.Link href="/profile">Profile</Nav.Link>
              <Nav.Link href="/deposit">Deposit</Nav.Link>
              <Nav.Link href="/withdraw">Withdraw</Nav.Link>
              <Nav.Link href="/transfer">Transfer</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>




            </Nav>
          </Navbar.Collapse>
          {/* <Navbar.Collapse
            id="responsive-navbar-nav "
            className="justify-content-end"
          >
            <div>
              <div className=" d-flex">
                <input
                  type="text"
                  placeholder="search your name here"
                  value={wordEntered}
                  onChange={handleFilter}
                  className="form-control search-width"
                />
                <div className="ms-2 searchIcon">
                  {filteredData.length === 0 ? (
                    <FaSearch />
                  ) : (
                    <RiCloseFill id="clearBtn" onClick={clearInput} />
                  )}
                </div>
              </div>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <br />
     

<Profile user={user1} />


<h5 className="container mb-2">Account Details</h5>

{/* Cards 1 */}
<section className="container">
        <div class="container row">
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Account Holder Name
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                    Vanessa B Densil
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-success shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Account Number
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                     83794944
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Swift/BIC Code
                    </div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                          ATIBAU3SXDR
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-warning shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      BSB Number
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                      326286
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


{/* cards 2 */}
<section className="container">
        <div class="container row">
          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Fixed Deposit:
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                    $ 0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-success shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Credit Card Balance
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                     $ 0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-info shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                    Savings
                    </div>
                    <div class="row no-gutters align-items-center">
                      <div class="col-auto">
                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                        $ 57,211,080.00
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-warning shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                    Available Balance
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">
                      $ 57,211,080.00 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

{/* <Card className="mt-4 mb-5 card ">
      <Card.Body className="card-body ">
        <Card.Title className="card-title">Your Account Details</Card.Title>
        <ListGroup variant="flush">
         
          <ListGroup.Item className="list-group-item">
            Fixed Deposit: ${fixedDeposit}
          </ListGroup.Item>
          <ListGroup.Item className="list-group-item">
            Credit Card Balance: ${creditCardBalance}
          </ListGroup.Item>
          <ListGroup.Item className="list-group-item">
            Savings: ${savings}
          </ListGroup.Item>
          <ListGroup.Item className="list-group-item">
            Available Balance: ${availableBalance} <span className="text-danger fw-bold">on hold</span>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card> */}
<br />
<BankDashboard />
<hr />
<ServicesList />
<hr />
<p className="container mt-5">
        At Aus Trust Investment Bank, we're focused on providing our clients
        with tailored investment solutions to meet their specific financial
        goals. Our experienced team offers services ranging from cooperate
        finance, equity research, to sales and trading. We also specialize in
        asset and risk management as well as wealth management, all geared
        towards maximizing our client's investment potential. We believe that
        with our expertise, we can guide you on the path to financial success.
        It would be a great pleasure to have a detailed discussion with you
        regarding your investment objectives, and how we can assist you in
        achieving them.
      </p>
      <hr />
{/* <h5 className="container">Transactions</h5>
      <Transaction transactions={transactions} /> */}

      {/* <AdminBackground /> */}
      <AdminFooter />
    </div>
  );
}

export default Admin;
