import React, { useState, useEffect } from "react";
// import { FaCreditCard } from 'react-icons/fa';
import "./User.css";
import { Navbar, Container, Dropdown, Nav } from "react-bootstrap";
import { FaUserAlt, FaSignOutAlt } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../config/Fire";
import AdminFooter from "./AdminFooter";
import { BsPerson, BsCalendar, BsEnvelope } from "react-icons/bs";
import { MdDateRange } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import {ImLocation} from "react-icons/im"
const UserDetailsPage = () => {
  const [user, loading] = useAuthState(auth);

  const navigate = useNavigate();

  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/sign-in");
  }, [user, loading, navigate]);

  return (
    <section>
      {/* top navigation bar */}
      <nav className="adminNavBar container-fluid d-flex justify-content-between align-items-center">
        <div>
          <a class="navbar-brand container" href>
            <span class="AV fw-bold">ATIB</span>{" "}
          </a>
        </div>
        <div>
          <Dropdown className="me-3 ">
            <Dropdown.Toggle className="user-icon" id="dropdown-basic">
              <FaUserAlt />
            </Dropdown.Toggle>{" "}
            <Dropdown.Menu>
              <Dropdown.Item className="dropdown-hover">
                {user?.email}
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-hover" onClick={logout}>
                logout <FaSignOutAlt />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
      {/* down navigation bar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="admin-down-navbar p-3"
        variant="dark"
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/admin">Home</Nav.Link>

              <Nav.Link href="/profile">Profile</Nav.Link>
              <Nav.Link href="/deposit">Deposit</Nav.Link>
              <Nav.Link href="/withdraw">Withdraw</Nav.Link>
              <Nav.Link href="/transfer">Transfer</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {/* <Navbar.Collapse
            id="responsive-navbar-nav "
            className="justify-content-end"
          >
            <div>
              <div className=" d-flex">
                <input
                  type="text"
                  placeholder="search your name here"
                  value={wordEntered}
                  onChange={handleFilter}
                  className="form-control search-width"
                />
                <div className="ms-2 searchIcon">
                  {filteredData.length === 0 ? (
                    <FaSearch />
                  ) : (
                    <RiCloseFill id="clearBtn" onClick={clearInput} />
                  )}
                </div>
              </div>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <br />
      <br />
      <div className="user-profile-container container">
        <h5 className="m-3 container">Welcome Vanessa,</h5>
        <p className="container">
          <MdDateRange className="fs-4 p-1 " />
          {date.toLocaleDateString()} <br />
          <BiTime className="fs-4 p-1" />
          {date.toLocaleTimeString()}
        </p>
        <div className="user-profile-card">
          <div className="user-profile-card-body">
            <div className="row">
              <div className="col-md-4">
                <div className="profile-picture">
                  <img
                    src="/img/profile-pic.jpg"
                    alt="Vanessa"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <h2 className="profile-name">Vanessa B Densil</h2>
                <div className="profile-details">
                  <div className="detail-item">
                    <BsPerson className="detail-icon" />
                    <span className="detail-label">Age:</span>
                    <span className="detail-value">34</span>
                  </div>
                  <div className="detail-item">
                    <BsCalendar className="detail-icon" />
                    <span className="detail-label">DOB:</span>
                    <span className="detail-value">18th May, 1989</span>
                  </div>
                  <div className="detail-item">
                    <BsEnvelope className="detail-icon" />
                    <span className="detail-label">Email:</span>
                    <span className="detail-value">
                      densilvanessa@gmail.com
                    </span>
                  </div>
                  <div className="detail-item">
                    <ImLocation className="detail-icon" />
                    <span className="detail-label">Address:</span>
                    <span className="detail-value">
                      6S Beverley Crescent, Marsfield, NSW 2122
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="container mt-5">
        At Aus Trust Investment Bank, we're focused on providing our clients
        with tailored investment solutions to meet their specific financial
        goals. Our experienced team offers services ranging from cooperate
        finance, equity research, to sales and trading. We also specialize in
        asset and risk management as well as wealth management, all geared
        towards maximizing our client's investment potential. We believe that
        with our expertise, we can guide you on the path to financial success.
        It would be a great pleasure to have a detailed discussion with you
        regarding your investment objectives, and how we can assist you in
        achieving them.
      </p>
      <br />
      <br /> <br />
      <AdminFooter />
    </section>
  );
};

export default UserDetailsPage;
