import React,{useEffect} from 'react';
import './DepositPage.css';
import { Navbar, Container, Dropdown, Nav } from "react-bootstrap";
import { FaUserAlt, FaSignOutAlt } from "react-icons/fa";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, logout } from "../config/Fire";
import AdminFooter from './AdminFooter';
// import { RiArrowGoBackFill } from 'react-icons/ri';
import './WithdrawalPage.css';
import ServicesList from './ServicesList';



const WithdrawPage = () => {
   
    const handleWithdrawal = (e) => {
        // Prevent the default form submission behavior
        e.preventDefault();
      
        // Retrieve the withdrawal amount entered by the user
        const withdrawalAmount = parseFloat(document.getElementById('amount').value);
      
        // Retrieve the selected withdrawal account
        const withdrawalAccount = document.getElementById('account').value;
      
        // Perform validation on the withdrawal amount
        if (isNaN(withdrawalAmount) || withdrawalAmount <= 0) {
          alert('Please enter a valid withdrawal amount.');
          return;
        }
      
        // Perform further logic for withdrawal
        // For example, you can make an API call or update the user's account balance
      
        // Reset the form fields after successful withdrawal
        document.getElementById('amount').value = '';
        document.getElementById('account').value = 'savings';
      
        // Display a success message to the user
        alert(`Withdrawal of $${withdrawalAmount.toFixed(2)} from ${withdrawalAccount} account was not successful. Please contact us through this email austrustinvestment@gmail.com.`);
      };
      


    const [user, loading] = useAuthState(auth);

    const navigate = useNavigate();
  
    useEffect(() => {
      if (loading) return;
      if (!user) return navigate("/sign-in");
    }, [user, loading, navigate]);
  





  return (
    



<section>

     {/* top navigation bar */}
     <nav className="adminNavBar container-fluid d-flex justify-content-between align-items-center">
        <div>
          <a class="navbar-brand container" href>
            <span class="AV fw-bold">ATIB</span>{" "}
          </a>
        </div>
        <div>
          <Dropdown className="me-3 ">
            <Dropdown.Toggle className="user-icon" id="dropdown-basic">
              <FaUserAlt />
            </Dropdown.Toggle>{" "}
            <Dropdown.Menu>
              <Dropdown.Item className="dropdown-hover">
                {user?.email}
              </Dropdown.Item>
              <Dropdown.Item className="dropdown-hover" onClick={logout}>
                logout <FaSignOutAlt />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
      {/* down navigation bar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        className="admin-down-navbar p-3"
        variant="dark"
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/admin">Home</Nav.Link>

              <Nav.Link href="/profile">Profile</Nav.Link>
              <Nav.Link href="/deposit">Deposit</Nav.Link>
              <Nav.Link href="/withdraw">Withdraw</Nav.Link>
              <Nav.Link href="/transfer">Transfer</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>




            </Nav>
          </Navbar.Collapse>
          {/* <Navbar.Collapse
            id="responsive-navbar-nav "
            className="justify-content-end"
          >
            <div>
              <div className=" d-flex">
                <input
                  type="text"
                  placeholder="search your name here"
                  value={wordEntered}
                  onChange={handleFilter}
                  className="form-control search-width"
                />
                <div className="ms-2 searchIcon">
                  {filteredData.length === 0 ? (
                    <FaSearch />
                  ) : (
                    <RiCloseFill id="clearBtn" onClick={clearInput} />
                  )}
                </div>
              </div>
            </div>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <br />
      <br />


      <div className="withdrawal-page">
      <div className="container withdrawCOntainer">
        <h1>Withdraw Funds</h1>
        <form>
          <div className="form-group">
            <label htmlFor="amount">Withdrawal Amount</label>
            <input type="number" className="form-control" id="amount" placeholder='$0'/>
          </div>
          <div className="form-group">
            <label htmlFor="account">Withdrawal Account</label>
            <select className="form-control" id="account">
              <option value="savings">Savings Account</option>
              <option value="checking">Checking Account</option>
            </select>
          </div>
          
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleWithdrawal}
          >
            Withdraw
          </button>
        </form>
        
      </div>
    </div>
    <hr />
<ServicesList />
<hr />
<p className="container mt-5">
        At Aus Trust Investment Bank, we're focused on providing our clients
        with tailored investment solutions to meet their specific financial
        goals. Our experienced team offers services ranging from cooperate
        finance, equity research, to sales and trading. We also specialize in
        asset and risk management as well as wealth management, all geared
        towards maximizing our client's investment potential. We believe that
        with our expertise, we can guide you on the path to financial success.
        It would be a great pleasure to have a detailed discussion with you
        regarding your investment objectives, and how we can assist you in
        achieving them.
      </p>
      <hr />

    <AdminFooter />

</section>

  );
};

export default WithdrawPage;
