import React,{useState,useEffect} from 'react';
import { Card } from 'react-bootstrap';
import { MdDateRange } from "react-icons/md";
import { BiTime } from "react-icons/bi";



function Profile({ user }) {
  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });


  return (
    <Card className="profile-card">
      <Card.Body>
        <Card.Title className="profile-title">{user ? user.name : 'Loading...'}</Card.Title>
        <Card.Text className="profile-text">Country: {user ? user.country : 'Loading...'}</Card.Text>
        {/* Add more user details as needed */}
        <Card.Text className='text-success'>
        <MdDateRange className="fs-4 p-1 " />
            {date.toLocaleDateString()}
        </Card.Text>
        <Card.Text className='text-danger'>
        <BiTime className="fs-4 p-1" />
              {date.toLocaleTimeString()}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Profile;
