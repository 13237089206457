import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";
import axios from "axios";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      ContactEmail: "",
      message: "",
    };
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ ContactEmail: event.target.value });
  }

  onMsgChange(event) {
    this.setState({ message: event.target.value });
  }

  submitEmail(e) {
    e.preventDefault();
    axios({
      method: "POST",
      url: "https://ghana-gold-vault.herokuapp.com/send/mail",
      data: this.state,
    }).then((response) => {
      if (response.data.status === "success") {
        alert("Message Sent.");
        this.resetForm();
      } else if (response.data.status === "fail") {
        alert("Message failed to send.");
      }
      window.location.reload();
    });
    
  }

  resetForm() {
    this.setState({ name: "", ContactEmail: "", message: "" });
  }


  render() {
    return (
      <div className=" text-dark">
        <NavBar />
        <br />
        <div>
          <p id="animation">
            Please do not to hesitate to contact us directly.
          </p>
        </div>

        <div class="container shadow-lg p-3 mb-5 bg-body rounded">
          <p>
            We are grateful that you came to our website. Please use this form
            to provide feedback regarding your visit, the site's content, or to
            report any broken links. Your suggestions and comments are
            appreciated.
          </p>
        </div>
        <br />

        <section>
          <div class="container shadow-lg p-3 mb-5 bg-body rounded">
            <h1>Contact Us</h1>
            <form onSubmit={this.submitEmail.bind(this)} method="POST">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  onChange={this.onNameChange.bind(this)}
                  value={this.state.name}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div class="form-group">
                <label for="email">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  name="ContactEmail"
                  placeholder="Enter your email"
                  required
                  value={this.state.contact_email}
                  onChange={this.onEmailChange.bind(this)}
                />
              </div>
              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  class="form-control"
                  name="message"
                  rows="3"
                  required
                  value={this.state.message}
                  onChange={this.onMsgChange.bind(this)}
                ></textarea>
              </div>
              <button class="btn btn-primary mt-4" type="submit">
                Submit
              </button>
            </form>
          </div>
        </section>
        <hr />
        <br />
       
        <hr />
       

        <Footer />
      </div>
    );
  }
}

export default Contact;
