import React from 'react'
import Background from './Background'

import NavBar from './NavBar'

function LoginPage() {
  return (
    <div>
        <NavBar />
        <Background />
        
    </div>
  )
}

export default LoginPage