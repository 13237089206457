import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import ForgotPassword from "./components/ForgotPassword";
import HomePage from "./components/HomePage";
import Register from "./components/Register";
import Home from "./components/Home";

import ContactPage from "./Dashboard/ContactPage";
import Form from "./components/Form";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Dashboard from "./Dashboard/Dashboard";
import Dashboard2 from "./Dashboard/Dashboard2";
import Dashboard3 from "./Dashboard/Dashboard3";
import DepositPage from "./Dashboard/DepositPage";
import WithdrawPage from "./Dashboard/WithdrawPage";
import TransferPage from "./Dashboard/TransferPage";
import UserDetailsPage from "./Dashboard/UserDetail";


class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />}>
            {" "}
          </Route>
          <Route path="register" element={<Register />}>
            {" "}
          </Route>
          <Route path="reset" element={<ForgotPassword />}>
            {" "}
          </Route>
          <Route path="admin" element={<Home />}>
            {" "}
          </Route>
          <Route path="Anthony-E-Gracey" element={<Dashboard />}>
          </Route>
          <Route path="Theodora-E-Hughes" element={<Dashboard2 />}>
          </Route>

          <Route path="Frank-Edwards" element={<Dashboard3 />}>
          </Route>
          

          <Route path="sign-in" element={<Form />}>
            {" "}
          </Route>
          <Route path="contact-us" element={<Contact />}>
            {" "}
          </Route>
          <Route path="services" element={<Services />}>
            {" "}
          </Route>

          <Route path="contact" element={<ContactPage />}>
            {" "}
          </Route>

          <Route path="deposit" element={<DepositPage />}>
            {" "}
          </Route>
          <Route path="withdraw"
          element={<WithdrawPage />}>
            {" "}
          </Route>

          <Route path="transfer" element={<TransferPage />}>
            {" "}
          </Route>

          <Route path="profile" element={<UserDetailsPage/>}>
            {" "}
          </Route>

        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
